

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #c5c5c5;
}


/* Tailwind CSS */
@tailwind base;
@tailwind components;
@tailwind utilities;
